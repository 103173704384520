import React from 'react';
import {
  Code2,
  Palette,
  Cloud,
  Building2,
  Mail,
  Phone,
  MapPin,
  MessageSquare,
  Info,
  ChevronRight,
  Facebook,
  Twitter,
  Linkedin
} from 'lucide-react';
import { FlipWords } from './components/ui/flip-words'; // Assicurati che il percorso sia corretto


// ServiceCard Component moved outside of App
function ServiceCard({ icon, title, description }) {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 flex flex-col items-center text-center transition-all duration-300 hover:shadow-lg">
      <div className="text-[#1980e6] text-4xl mb-4">
        {icon}
      </div>
      <h3 className="text-xl font-semibold mb-3">{title}</h3>
      <p className="text-sm text-gray-600">{description}</p>
    </div>
  );
}

export function FlipWordsDemo() {
  const words = [
    "innovative",
    "scalabili",
    "sicure",
    "personalizzate",
    "cloud-ready",
    "affidabili"
  ];

  return (
    <div className="h-[40rem] flex justify-center items-center px-4 font-manrope">
      <div className="text-4xl mx-auto font-normal text-gray-900 text-center">
        SYSAP Software
        <br />
        crea soluzioni
        <br />
        <FlipWords words={words} />
      </div>
    </div>
  );
}

function App() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="relative flex min-h-screen flex-col bg-white overflow-x-hidden font-sans">
      {/* Header */}
      <header className="sticky top-0 z-50 bg-white border-b border-gray-100 px-6 lg:px-10 py-4">
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          <div className="flex items-center gap-4 text-gray-900">
            <div className="w-8 h-8">
            <img src="/logo_SYSAP_512x512.svg" alt="SYSAP Software Logo" className="w-full h-full" />
            </div>
            <h2 className="text-xl font-bold tracking-tight">SYSAP Software</h2>
          </div>
          
          <nav className="hidden md:flex items-center gap-8">
            <a className="text-sm font-medium text-gray-700 hover:text-blue-600 transition-colors" href="#services">Servizi</a>
            <a className="text-sm font-medium text-gray-700 hover:text-blue-600 transition-colors" href="#about">Chi Siamo</a>
            <a className="text-sm font-medium text-gray-700 hover:text-blue-600 transition-colors" href="#contact">Contatti</a>
            <a href="https://www.sysap.com/" className="inline-flex items-center justify-center rounded-xl px-4 py-2.5 bg-blue-600 text-white font-medium hover:bg-blue-700 transition-colors">
              Inizia Ora
            </a>
          </nav>
        </div>
      </header>

      <main>
        {/* Hero Section */}
        <FlipWordsDemo />

        {/* Services Section */}
        <section id="services" className="py-20 bg-gray-50">
          <div className="max-w-7xl mx-auto px-6 lg:px-10">
            <div className="text-center max-w-3xl mx-auto mb-16">
              <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">I Nostri Servizi</h2>
              <p className="text-xl text-gray-600">
                Offriamo soluzioni innovative per aiutare startup e imprese a costruire prodotti scalabili, sicuri e intuitivi.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              <ServiceCard 
                icon={<Code2 size={32} />}
                title="Sviluppo Personalizzato"
                description="Trasformiamo le tue idee in soluzioni software su misura, utilizzando le tecnologie più avanzate per garantire prestazioni e scalabilità."
              />
              <ServiceCard 
                icon={<Palette size={32} />}
                title="Design del Prodotto"
                description="Creiamo esperienze utente intuitive e coinvolgenti, combinando estetica e funzionalità per prodotti che si distinguono sul mercato."
              />
              <ServiceCard 
                icon={<Building2 size={32} />}
                title="Servizi per PA"
                description="Modernizziamo i servizi pubblici con soluzioni digitali innovative, migliorando l'efficienza e la trasparenza nella pubblica amministrazione."
              />
              <ServiceCard 
                icon={<Cloud size={32} />}
                title="DevOps & Cloud"
                description="Ottimizziamo il tuo flusso di sviluppo e distribuzione, garantendo scalabilità e sicurezza attraverso soluzioni cloud all'avanguardia."
              />
            </div>
          </div>
        </section>

        {/* About Section */}
        <section id="about" className="py-20">
          <div className="max-w-7xl mx-auto px-6 lg:px-10">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 text-center mb-16">Chi Siamo</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
              <div>
                <p className="text-lg text-gray-600 mb-6">
                  SYSAP Software è un'azienda leader nel settore delle soluzioni software innovative. 
                  Da oltre un decennio, il nostro team di esperti si dedica a trasformare le sfide 
                  digitali in opportunità di crescita per le aziende di ogni dimensione.
                </p>
                <p className="text-lg text-gray-600 mb-8">
                  La nostra missione è semplice: fornire tecnologie all'avanguardia e servizi 
                  personalizzati che permettano ai nostri clienti di eccellere nel loro settore. 
                  Crediamo nella potenza dell'innovazione e nell'importanza di costruire relazioni 
                  durature con i nostri partner.
                </p>
                <a href="#contact" className="inline-flex items-center justify-center rounded-xl px-6 py-3 bg-blue-600 text-white font-medium hover:bg-blue-700 transition-colors">
                  Contattaci
                </a>
              </div>
              <div className="rounded-2xl overflow-hidden shadow-xl">
                <img 
                  src="https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
                  alt="SYSAP Team" 
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section id="contact" className="py-20 bg-gray-50">
          <div className="max-w-7xl mx-auto px-6 lg:px-10">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 text-center mb-16">Contatti</h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div>
                <div className="bg-white rounded-2xl shadow-lg p-8">
                  <h3 className="text-2xl font-semibold text-gray-900 mb-6">Contatto rapido</h3>
                  
                  <div className="space-y-6">
                    <div className="flex items-start gap-4">
                      <MessageSquare className="text-blue-600 shrink-0 mt-1" size={24} />
                      <p className="text-gray-600">
                        Per ricevere assistenza rapida, puoi utilizzare la chat on-line 
                        (il pulsante in basso a destra)
                      </p>
                    </div>

                    <div className="flex items-start gap-4">
                      <Phone className="text-blue-600 shrink-0 mt-1" size={24} />
                      <div>
                        <p className="text-gray-900 font-medium">Help desk: 080.9696.550</p>
                        <p className="text-gray-500">(lun-ven 9-13 16-18)</p>
                      </div>
                    </div>

                    <div className="pt-4 border-t border-gray-100">
                      <h4 className="text-lg font-semibold text-gray-900 mb-4">Email</h4>
                      
                      <div className="space-y-4">
                        <div className="flex items-start gap-4">
                          <Mail className="text-blue-600 shrink-0 mt-1" size={24} />
                          <div>
                            <a href="mailto:assistenza@sysap.com" className="text-blue-600 hover:underline">
                              assistenza@sysap.com
                            </a>
                            <p className="text-sm text-gray-500">
                              (assistenza tecnica - non inviare PEC)
                            </p>
                          </div>
                        </div>

                        <div className="ml-12 space-y-2">
                          <a href="mailto:info@sysap.com" className="block text-blue-600 hover:underline">
                            info@sysap.com
                          </a>
                          <a href="mailto:privacy@sysap.com" className="block text-blue-600 hover:underline">
                            privacy@sysap.com
                          </a>
                          <a href="mailto:job@sysap.com" className="block text-blue-600 hover:underline">
                            job@sysap.com
                          </a>
                          <a href="mailto:sysap@pec.it" className="block text-blue-600 hover:underline">
                            sysap@pec.it
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="pt-6">
                      <a 
                        href="https://www.sysap.com/contatti/" 
                        className="inline-flex items-center gap-2 rounded-xl px-6 py-3 bg-blue-600 text-white font-medium hover:bg-blue-700 transition-colors"
                      >
                        <Info size={20} />
                        Modulo di Contatto Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-2xl shadow-lg p-8">
                <h3 className="text-2xl font-semibold text-gray-900 mb-6">Orari di Assistenza</h3>
                <div className="space-y-6">
                  <p className="text-gray-600">
                    Il nostro team di assistenza è disponibile per aiutarti:
                  </p>
                  <div className="border-l-4 border-blue-600 pl-6">
                    <h4 className="font-semibold text-gray-900 mb-2">Lunedì - Venerdì</h4>
                    <p className="text-gray-600">Mattina: 9:00 - 13:00</p>
                    <p className="text-gray-600">Pomeriggio: 16:00 - 18:00</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-12">
        <div className="max-w-7xl mx-auto px-6 lg:px-10">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div>
              <h3 className="text-lg font-bold mb-4">SYSAP Software</h3>
              <p className="text-gray-400">
                Soluzioni software innovative per il tuo successo.
              </p>
            </div>
            
            <div>
              <h3 className="text-lg font-bold mb-4">Link Rapidi</h3>
              <ul className="space-y-2">
                <li>
                  <a href="#services" className="text-gray-400 hover:text-white transition-colors inline-flex items-center gap-2">
                    <ChevronRight size={16} />
                    Servizi
                  </a>
                </li>
                <li>
                  <a href="#about" className="text-gray-400 hover:text-white transition-colors inline-flex items-center gap-2">
                    <ChevronRight size={16} />
                    Chi Siamo
                  </a>
                </li>
                <li>
                  <a href="#contact" className="text-gray-400 hover:text-white transition-colors inline-flex items-center gap-2">
                    <ChevronRight size={16} />
                    Contatti
                  </a>
                </li>
                <li>
                  <a href="/privacy_policy/" className="text-gray-400 hover:text-white transition-colors inline-flex items-center gap-2">
                    <ChevronRight size={16} />
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-lg font-bold mb-4">Seguici</h3>
              <div className="flex space-x-4">
                <a href="#" className="text-gray-400 hover:text-white transition-colors" aria-label="Facebook">
                  <Facebook size={24} />
                </a>
                <a href="#" className="text-gray-400 hover:text-white transition-colors" aria-label="Twitter">
                  <Twitter size={24} />
                </a>
                <a href="#" className="text-gray-400 hover:text-white transition-colors" aria-label="LinkedIn">
                  <Linkedin size={24} />
                </a>
              </div>
            </div>
          </div>

          <div className="mt-12 pt-8 border-t border-gray-800">
            <div className="text-center text-gray-400 text-sm">
              &copy; {currentYear} SYSAP Software. Tutti i diritti riservati.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;